import classnames from "classnames";
import { useState } from "react";
import useScrollspy from "../hooks/useScrollspy";

export const Header = ({ darkReaderEnabled, setDarkMode }) => {
  const [sideNavOpen, setSideNavOpen] = useState(false);

  const ids = [
    "hero",
    "about",
    "achievements",
    "portfolio",
    "resume",
    "services",
    "publications",
    "contact",
  ];
  const activeId = useScrollspy(ids, 200);

  const handleChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      if (darkReaderEnabled == false) {
        setDarkMode(true);
      }
    } else {
      setDarkMode(false);
    }
  };

  const handleClick = () => {
    if (sideNavOpen) {
      document.body.classList.remove("mobile-nav-active");
      setSideNavOpen(false);
    } else {
      document.body.classList.add("mobile-nav-active");
      setSideNavOpen(true);
    }
  };

  return (
    <div>
      {/* <!-- ======= Mobile nav toggle button ======= --> */}
      <button
        type="button"
        onClick={handleClick}
        className="mobile-nav-toggle d-xl-none"
      >
      <i
        className={classnames("bi mobile-nav-toggle", {
          "bi-list": !sideNavOpen,
          "bi-x bi-co": sideNavOpen,
        })}
      ></i>
      </button>
      {/* <i className="bi bi-list mobile-nav-toggle d-xl-none"></i> */}
      {/* <!-- ======= Header ======= --> */}
      <header id="header" className="d-flex flex-column justify-content-center">
        <nav id="navbar" className="navbar nav-menu">
          <ul>
            <li>
              <div className="ms-3 ms-md-0 py-3">
                <input
                  type="checkbox"
                  className="checkbox"
                  id="checkbox"
                  onChange={handleChange}
                  checked={darkReaderEnabled}
                />
                <label
                  htmlFor="checkbox"
                  className="label"
                  onClick={handleClick}
                >
                  <i className="fas fa-moon"></i>
                  <i className="fas fa-sun"></i>
                  <div className="ball"></div>
                </label>
              </div>
            </li>
            <li>
              <a
                onClick={handleClick}
                href="#hero"
                className={classnames("nav-link scrollto", {
                  active: activeId === "hero",
                })}
              >
                <i className="bx bx-home"></i> <span>Home</span>
              </a>
            </li>
            <li>
              <a
                onClick={handleClick}
                href="#about"
                className={classnames("nav-link scrollto", {
                  active: activeId === "about",
                })}
              >
                <i className="bx bx-user"></i> <span>About</span>
              </a>
            </li>
            <li>
              <a
                onClick={handleClick}
                href="#resume"
                className={classnames("nav-link scrollto", {
                  active: activeId === "resume",
                })}
              >
                <i className="bx bx-file-blank"></i> <span>Resume</span>
              </a>
            </li>
            <li>
              <a
                onClick={handleClick}
                href="#portfolio"
                className={classnames("nav-link scrollto", {
                  active: activeId === "portfolio",
                })}
              >
                <i className="bx bx-book-content"></i> <span>Projects</span>
              </a>
            </li>
            <li>
              <a
                onClick={handleClick}
                href="#services"
                className={classnames("nav-link scrollto", {
                  active: activeId === "services",
                })}
              >
                <i className="bx bx-server"></i> <span>Blogs</span>
              </a>
            </li>
            <li>
              <a
                onClick={handleClick}
                href="#achievements"
                className={classnames("nav-link scrollto", {
                  active: activeId === "achievements",
                })}
              >
                <i
                  className="bx 
          bxs-megaphone"
                ></i>{" "}
                <span>Achievements</span>
              </a>
            </li>
            <li>
              <a
                onClick={handleClick}
                href="#publications"
                className={classnames("nav-link scrollto", {
                  active: activeId === "publications",
                })}
              >
                <i
                  className="bx 
          bx-book-open"
                ></i>{" "}
                <span>Publications</span>
              </a>
            </li>
            <li>
              <a
                onClick={handleClick}
                href="#contact"
                className={classnames("nav-link scrollto", {
                  active: activeId === "contact",
                })}
              >
                <i className="bx bx-envelope"></i> <span>Contact</span>
              </a>
            </li>
          </ul>
        </nav>
        {/* <!-- .nav-menu --> */}
      </header>
      {/* <!-- End Header --> */}
    </div>
  );
};
