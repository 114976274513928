import React from 'react'

export const Footer = () => {
  return (
    <div>
         {/* <!-- ======= Footer ======= --> */}
  <footer id="footer">
    <div className="container">
      <h3>Avik Kundu</h3>
      <span>Software Engineer <strong>@RedHat</strong> | AWS Community Builder | Full Stack Developer | DevOps & Cloud</span>
      <br/><br/>
      {/* <!-- <p>Find out more about me!</p> --> */}

      <div className="copyright mb-4">
        <strong><span>DISCUSS A PROJECT OR JUST WANT TO SAY HI? MY INBOX IS OPEN FOR ALL.</span></strong>
        
        {/* <!--  Copyright <strong><span>MyResume</span></strong>. All Rights Reserved --> */}
      </div>


      <div className="social-links">
        <a href="https://twitter.com/avik6028" className="twitter"><i className="bx bxl-twitter"></i></a>
        <a href="https://www.facebook.com/avikonweb" className="facebook"><i className="bx bxl-facebook"></i></a>
        <a href="https://www.instagram.com/avik6028" className="instagram"><i className="bx bxl-instagram"></i></a>
        <a href="https://www.linkedin.com/in/avik-kundu-0b837715b" className="google-plus"><i className="bx bxl-linkedin"></i></a>
        <a href="https://github.com/Lucifergene/" className="linkedin"><i className="bx bxl-github"></i></a>
        <a href="https://dev.to/avik6028" className="linkedin"><i className="bx bxl-dev-to"></i></a>
      </div>
      
      <div className="credits">
        {/* Theme Inspired from BootstrapMade 🅱️<br /> */}
        &copy; {new Date().getFullYear()} All Rights Reserved
        {/* <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: [license-url] -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/free-html-bootstrap-template-my-resume/ -->
        <!-- Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> --> */}
      </div>
    </div>
  </footer>
  {/* <!-- End Footer --> */}
    </div>
  )
}
