import React from "react";

export const About = () => {
  return (
    <div>
      {" "}
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>About Me.</h2>
            <p>
              I am a Software Engineer at <a href="www.redhat.com" target="_blank">Red Hat</a> and a Computer Science
              graduate (c/o 2022) from India. I am a full-stack developer,
              opensource contributor, and tech content creator proficient in
              DevOps and Cloud. I love to write articles and tutorials on
              various tools and technologies and have given talks on
              cloud-native technologies in various communities.
              <br />I love to learn new technologies, share my learnings and
              meet new people. <strong>Let's connect!</strong> ✨
            </p>
          </div>
          <div className="container py-4 px-4 containerBox" >
            <div className="row">
              <div className="col-lg-4 text-center mb-3">
                <img
                  loading="lazy"
                  style={{"width": "80%"}}
                  src="assets/img/avikkundu.webp"
                  className="img-fluid framed"
                  alt=""
                />
              </div>
              <div className="col-lg-8 pt-4 pt-lg-0 content">
                <h3>Software Engineer | OpenSource Contributor </h3>
                {/* <!-- <p className="fst-italic">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore
            magna aliqua.
          </p> --> */}
                <div className="row py-4">
                  <div className="col-lg-6">
                    <ul>
                      {/* <!-- <li><i className="bi bi-chevron-right"></i> <strong>Birthday:</strong> <span>1 May 1995</span></li> --> */}
                      <li>
                        <i className="bi bi-chevron-right"></i>{" "}
                        <strong>Website:</strong>{" "}
                        <span>
                          <a href="https://www.avikkundu.in">
                            www.avikkundu.in
                          </a>
                        </span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right"></i>{" "}
                        <strong>Twitter:</strong>{" "}
                        <span>
                          <a href="https://twitter.com/avik6028">avik6028</a>
                        </span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right"></i>{" "}
                        <strong>Email:</strong>{" "}
                        <span>
                          <a href="mailto:avikkundu8d@gmail.com">
                            avikkundu8d@gmail.com
                          </a>
                        </span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right"></i>{" "}
                        <strong>City:</strong>{" "}
                        <span>
                          <a href="https://www.google.com/maps/place/Bengaluru,+Karnataka">
                            Bangalore, India
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <ul>
                      <li>
                        <i className="bi bi-chevron-right"></i>{" "}
                        <strong>GitHub Username:</strong>{" "}
                        <span>
                          <a href="https://github.com/Lucifergene/">
                            Lucifergene
                          </a>
                        </span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right"></i>{" "}
                        <strong>Blog:</strong>{" "}
                        <span>
                          <a href="https://avikkundu.medium.com/">
                            avikkundu.medium.com
                          </a>
                        </span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right"></i>{" "}
                        <strong>LinkedIn:</strong>{" "}
                        <span>
                          <a href="https://www.linkedin.com/in/avik-kundu-0b837715b">
                            avik-kundu-0b837715b
                          </a>
                        </span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right"></i>{" "}
                        <strong>Hackerrank:</strong>{" "}
                        <span>
                          <a href="https://www.hackerrank.com/avik6028">
                            avik6028
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <p>
                  I am a self driven and versatile individual with a strong
                  interest in Development and passion for strategical
                  development, public speaking, leadership activities and
                  writing✍🏼. I am a music enthusiast as well and a Football
                  Player⚽. I desire to make a positive impact on the people
                  around me and hope to achieve this through personal projects,
                  internships, hackathons and other experiences.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Section --> */}
    </div>
  );
};
