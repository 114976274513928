import Typed from "typed.js";
import { useEffect, useRef } from "react";

export const Hero = () => {
  const el = useRef<any>(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "Software Engineer",
        "Open-Source Contributor",
        "Speaker at conferences",
        "Full-Stack Developer",
        "Cloud Native Enthusiast",
      ], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 300,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 100,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div>
      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero" className="d-flex flex-column justify-content-center">
        <div className="container" data-aos="zoom-in" data-aos-delay="100">
          <h1>Avik Kundu</h1>
          <p>
            I'm <span ref={el}></span>
          </p>
          <div className="social-links py-3">
            <a
              href="https://www.linkedin.com/in/avik-kundu-0b837715b"
              target="_blank"
              className="linkedin"
            >
              <i className="bx bxl-linkedin"></i>
            </a>
            <a
              href="https://twitter.com/avik6028"
              target="_blank"
              className="twitter"
            >
              <i className="bx bxl-twitter"></i>
            </a>
            <a
              href="https://github.com/Lucifergene/"
              target="_blank"
              className="github"
            >
              <i className="bx bxl-github"></i>
            </a>
            <a
              href="https://avikkundu.medium.com/"
              target="_blank"
              className="medium"
            >
              <i className="bx bxl-medium"></i>
            </a>
            {/* <!-- <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a> --> */}
          </div>

          <a
            href="mailto:avikkundu8d@gmail.com"
            className="btn btn-outline-primary"
          >
            Hire Me
          </a>
          <a
            href="https://drive.google.com/file/d/11Ws3nFlWCiZYKAgwBoQIxKtQauh4jziJ/view?usp=sharing"
            target="_blank"
            className="btn btn-outline-success"
            style={{ marginLeft: "7px" }}
          >
            Resume
          </a>
        </div>
      </section>
      {/* <!-- End Hero --> */}
    </div>
  );
};
