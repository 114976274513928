import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, Scrollbar } from "swiper";
import { Row, Col } from "react-bootstrap";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

export const Achievements = () => {
  return (
    <div>
      {/* <!-- ======= Achievements Section ======= --> */}
      <section id="achievements" className="testimonials section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Achievements</h2>
          </div>

          <div className="container py-4 px-4 containerBox">
          <div
            className="testimonials-slider swiper-container"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <Swiper
              modules={[Navigation, Pagination, Autoplay, Scrollbar]}
              pagination={{
                type: "bullets",
                el: ".swiper-pagination",
                clickable: true,
              }}
              autoplay={{ delay: 3000 }}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {/* <!-- Slide 1 --> */}
              <SwiperSlide>
                <div className="testimonial-item">
                  <Row>
                    <Col
                      lg={5}
                      md={6}
                      className="d-flex align-items-stretch px-4"
                    >
                      <a href="https://www.youtube.com/watch?v=lhdiBAoL80s">
                        <img
                          loading="lazy"
                          className="img-fluid"
                          height="300"
                          src="assets/img/testimonials/civo.png"
                        />
                      </a>
                    </Col>
                    <Col lg={7} md={6} className="d-flex align-items-stretch">
                      <span style={{ textAlign: "justify" }}>
                        <h3>Civo Hackathon 2021 Winner</h3>
                        In this hackathon, we had to develop an application and
                        use the Civo Kubernetes Platform for deploying the
                        solution. <br /><br />I had developed a Computer-Aided
                        Diagnostic System which is used to predict whether a
                        person has been infected with COVID-19. The application
                        was deployed on Civo K3s Cluster and had developed a
                        complete DevOps pipeline with GitHub Actions for
                        Continuous Integration and ArgoCD for Continuous
                        Deployment. The Prometheus Monitoring Stack was also
                        deployed on the cluster for visualizing the application
                        metrics.
                        <br /><br />
                        Live Project: <a href="https://covid-predictioned.herokuapp.com/">Covid-19 Prognosis</a>
                      </span>
                    </Col>
                  </Row>
                </div>
              </SwiperSlide>

              {/* <!-- Slide 2 --> */}
              <SwiperSlide>
                <div className="testimonial-item">
                  <Row>
                    <Col
                      lg={5}
                      md={6}
                      className="d-flex align-items-stretch px-4"
                    >
                      <a href="https://youtu.be/NLMIK_Pn0as?t=2523">
                        <img
                          loading="lazy"
                          className="img-fluid"
                          height="300"
                          src="assets/img/testimonials/inframinds.jfif"
                        />
                      </a>
                    </Col>
                    <Col lg={7} md={6} className="d-flex align-items-stretch">
                      <span style={{ textAlign: "justify" }}>
                        <h3>TCS Inframinds Season-4 Runners-Up</h3>
                        I had brought laurels to my university by achieving a
                        1st runner-up position in the prestigious TCS Inframind
                        2021, Season-4. I had presented my idea under the theme
                        "Autonomous Infrastructure using DevOps and Cloud" and
                        my problem statement was "To build a scalable, secure,
                        and self-healing architecture on a cloud premise". For
                        demonstration, I had deployed a "3-tier WordPress
                        cluster on top of Microsoft Azure" using various DevOps
                        tools like Docker, Jenkins, Kubernetes, Terraform, and
                        Ansible.
                        <br />
                        TCS Inframind is an annual Hackathon conducted by TCS
                        Cognitive Business Operations (CBO), a global leader in
                        providing Datacenter, Cloud, and Digital Workplace
                        Transformation Solutions. The event consisted of three
                        rounds: online quiz, prototype submission & video
                        presentation, and hackathon & grand finale. The themes
                        for season-4 were Sustainability & Wellness, Enterprise
                        AI, Autonomous Infrastructure, and Hyper Connectivity.
                      </span>
                    </Col>
                  </Row>
                </div>
              </SwiperSlide>

              {/* <!-- Slide 3 --> */}
              <SwiperSlide>
                <div className="testimonial-item">
                  <Row>
                    <Col
                      lg={5}
                      md={6}
                      className="d-flex align-items-stretch px-4"
                    >
                      <a href="#">
                        <img
                          loading="lazy"
                          className="img-fluid"
                          height="300"
                          src="assets/img/testimonials/aws-community.jpg"
                        />
                      </a>
                    </Col>
                    <Col lg={7} md={6} className="d-flex align-items-stretch">
                      <span style={{ textAlign: "justify" }}>
                        <h3>AWS Community Builder</h3>
                        <br />
                        Selected among the top 10% of applicants globally for
                        AWS Community Builders program. <br />
                        <br />
                        The AWS Community Builders program offers technical
                        resources, mentorship, and networking opportunities to
                        AWS enthusiasts and emerging thought leaders who are
                        passionate about sharing knowledge and connecting with
                        the technical community.
                      </span>
                    </Col>
                  </Row>
                </div>
              </SwiperSlide>

              {/* <!-- Slide 4 --> */}
              <SwiperSlide>
                <div className="testimonial-item">
                  <Row>
                    <Col
                      lg={5}
                      md={6}
                      className="d-flex align-items-stretch px-4"
                    >
                      <a href="#">
                        <img
                          loading="lazy"
                          className="img-fluid"
                          height="300"
                          src="assets/img/testimonials/mlsa.jpg"
                        />
                      </a>
                    </Col>
                    <Col lg={7} md={6} className="d-flex align-items-stretch">
                      <span style={{ textAlign: "justify" }}>
                        <h3>Microsoft Learn Student Ambassador</h3>
                        <br />
                        Selected among the top 3000 students globally for
                        Microsoft Learn Student Ambassador Global Program.{" "}
                        <br />
                        Learn Student Ambassadors are a global group of campus
                        leaders who are eager to help fellow students, create
                        robust tech communities and develop technical and career
                        skills fot the future.
                      </span>
                    </Col>
                  </Row>
                </div>
              </SwiperSlide>

              {/* <!-- Slide 5 --> */}
              <SwiperSlide>
                <div className="testimonial-item">
                  <Row>
                    <Col
                      lg={5}
                      md={6}
                      className="d-flex align-items-stretch px-4"
                    >
                      <a href="#">
                        <img
                          loading="lazy"
                          className="img-fluid"
                          height="300"
                          src="assets/img/testimonials/novate.jpg"
                        />
                      </a>
                    </Col>
                    <Col lg={7} md={6} className="d-flex align-items-stretch">
                      <span style={{ textAlign: "justify" }}>
                        <h3>Novate COVID Hackathon Finalists</h3>
                        <br />
                        Our team was one of the top finalists in the Hackathon
                        organized by Chitkara University. We had developed an
                        e-PDS system to enhance the current PDS system, which
                        had a lot of issues to be dealt during the COVID-19
                        pandemic. <br />
                        NOVATE+ is conceptualized to channelize the knowledge
                        and energy of youth towards becoming active partners in
                        the economic development process, inculcate a culture of
                        innovation-driven entrepreneurship and act as an
                        institutional mechanism for providing various services
                        on all aspects of enterprise building to the budding S &
                        T entrepreneurs.
                      </span>
                    </Col>
                  </Row>
                </div>
              </SwiperSlide>

              {/* <!-- Slide 6 --> */}
              <SwiperSlide>
                <div className="testimonial-item">
                  <Row>
                    <Col
                      lg={5}
                      md={6}
                      className="d-flex align-items-stretch px-4"
                    >
                      <a href="#">
                        <img
                          loading="lazy"
                          className="img-fluid"
                          height="300"
                          src="assets/img/testimonials/optum.jpg"
                        />
                      </a>
                    </Col>
                    <Col lg={7} md={6} className="d-flex align-items-stretch">
                      <span style={{ textAlign: "justify" }}>
                        <h3>Optum Stratethon Season 2 (Top 25)</h3>
                        <br />
                        Optum organizes 'Stratethon' — a one of its kind
                        initiative focused on identifying thinkers and
                        innovators from across various business and engineering
                        schools in India.
                        <br />
                        Developed an Integrated Medical Platform tailored for
                        the specific needs of the Doctors. Through this
                        platform, they can efficiently access their patient
                        information in real-time. Easy to track Appointments,
                        Payments and Review Reports. Thus, Doctors can focus
                        more on their job.
                        <br /> Developed a Android Application through which
                        patients can upload their symptoms and X-Rays and can
                        get predictions.
                        <br /> Had also integrated Parkinsons disease prediction
                        model along with heart rate monitor.
                      </span>
                    </Col>
                  </Row>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="swiper-pagination"></div>
          </div>
          </div>
        </div>
      </section>
      {/* <!-- End Achievements Section --> */}
    </div>
  );
};
