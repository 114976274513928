import React from "react";
import classnames from "classnames";
import useCheckMobileScreen from "../hooks/useCheckMobileScreen";

export const Resume = () => {
  const isMobile: boolean = useCheckMobileScreen();
  return (
    <div>
      {/* <!-- ======= Resume Section ======= --> */}
      <section id="resume" className="resume">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Resume</h2>
            {/* <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint
            consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit
            in iste officiis commodi quidem hic quas.</p> --> */}
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className={classnames("container py-4 px-4 containerBox", {"mb-3" : isMobile})}>
                <h3 className="resume-title">Professional Experience</h3>
                <div className="resume-item">
                  <h4>Associate Software Engineer</h4>
                  <h5>July 2022 - Present</h5>
                  <p>
                    <b>Red Hat, Bengaluru, IND </b>
                  </p>
                  <ul>
                    <li>
                      Completed Internship in the Openshift Developer Console
                      Team <br />
                      <strong>(Jan 2022 - June 2022 )</strong>
                    </li>
                    <li>
                      Developing new features to make the Developer Console
                      experience better for customers.
                    </li>
                    {/* <!-- <li>Integrating Ansible in the existing automation workflow for configuration management.</li>
                <li>Implementing new features in the existing Web Application Framework and working with Cloud SDKs.</li> --> */}
                  </ul>
                  <br />
                </div>
                <div className="resume-item">
                  <h4>DevOps Engineer Internship</h4>
                  <h5>July 2021 - December 2021</h5>
                  <p>
                    <b>HighRadius Technologies, Hyderabad, IND </b>
                  </p>
                  <ul>
                    <li>
                      Working with the DevOps team for automating infrastructure
                      provisioning and management.
                    </li>
                    <li>
                      Integrating Ansible in the existing automation workflow
                      for configuration management.
                    </li>
                    <li>
                      Implementing new features in the existing Web Application
                      Framework and working with Cloud SDKs.
                    </li>
                  </ul>
                  <br />
                </div>
                <div className="resume-item">
                  <h4>Cloud Engineer Internship</h4>
                  <h5>April 2021 - July 2021</h5>
                  <p>
                    <b>Thoucentric Labs, Bengaluru, IND</b>
                  </p>
                  <ul>
                    <li>
                      Worked with Azure DevOps for setting up build and
                      deployment pipelines and Cron jobs for automatic
                      management of AKS clusters.
                    </li>
                    <li>
                      Developed IAC using Terraform and installed software on
                      AKS clusters using Ansible and Helm
                    </li>
                    <li>
                      Developed Data Ingestion pipeline using Apache Nifi to
                      bring data from various sources to a common sink
                      automatically.
                    </li>
                    {/* <!-- <li>Created 4+ design presentations and proposals a month for clients and account managers</li> --> */}
                  </ul>
                </div>
                <br />
                <br />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="container py-4 px-4 containerBox">
                <h3 className="resume-title">Community Experience</h3>

                <div className="resume-item">
                  <h4>AWS Community Builder</h4>
                  <h5>Aug 2021 - Present</h5>
                  <p>
                    <b>Speaker | Technical Content Writer</b>
                  </p>
                  <ul>
                    <li>
                      The AWS Community Builders program offers technical
                      resources, mentorship, and networking opportunities to AWS
                      enthusiasts and emerging thought leaders who are
                      passionate about sharing knowledge and connecting with the
                      technical community.
                    </li>
                  </ul>
                </div>

                <div className="resume-item">
                  <h4>Microsoft Learn Student Ambassador </h4>
                  <h5>Jan 2021 - Present</h5>
                  <p>
                    <b>Speaker & Event Coordinator</b>
                  </p>
                  <ul>
                    <li>
                      Learn Student Ambassadors are a global group of campus
                      leaders who are eager to help fellow students, create
                      robust tech communities and develop technical and career
                      skills fot the future.
                    </li>
                    <li>
                      We host live events every weekend, where we walk-through
                      some new technologies related to Microsoft.
                    </li>
                  </ul>
                </div>

                <div className="resume-item">
                  <h4>Mozilla Bhubaneswar Society</h4>
                  <h5>April 2021 - Mar 2022</h5>
                  <p>
                    <b>University Technical Lead</b>
                  </p>
                  <ul>
                    <li>
                      We organize online webinar sessions where we invite
                      various Open-source developers and prominent people from
                      the Mozilla Community.
                    </li>
                  </ul>
                </div>

                <div className="resume-item">
                  <h4>E-Labs Society </h4>
                  <h5>April 2019 - Dec 2021</h5>
                  <p>
                    <b>Trainer | Community Coordinator</b>
                  </p>
                  <ul>
                    <li>
                      We mentor new university students on new technologies like
                      Web Development, Machine Learning, Java, AR/VR, and
                      Android.
                    </li>
                    <li>
                      {" "}
                      We follow a peer-learning model, where seniors teach
                      juniors and the cycle continues every year. Currently, we
                      have taught 600 students amongst 3 semesters offline.
                    </li>
                  </ul>
                </div>

                {/* <div className="resume-item">
                <h4>University Entrepreneurship Cell </h4>
                <h5>April 2020 - Present</h5>
                <p>
                  <b>Advisory Board Member</b>
                </p>
                <ul>
                  <li>
                    Actively looking into the development of the Technical team
                    of ECell. Providing insightful knowledge during development
                    of applications involving high traffic.
                  </li>
                  <li>
                    Part of the Advisory body for the Startup Conclave, which is
                    a flagship event in the organization. Was incharge of
                    Measuring technical feasibilities for bringing on the event
                    successfully.
                  </li>
                </ul>
              </div> */}
              </div>
            </div>
          </div>

          <br />
          <br />
          <br />

          <div className="row">
            <div className="col-lg-6">
            <div className={classnames("container py-4 px-4 containerBox", {"mb-3" : isMobile})}>
                {/* <!-- <h3 className="resume-title">Summary</h3>
            <div className="resume-item pb-0">
              <h4>Brandon Johnson</h4>
              <p><em>Innovative and deadline-driven Graphic Designer with 3+ years of experience designing and
                  developing user-centered digital/print marketing material from initial concept to final, polished
                  deliverable.</em></p>
              <ul>
                <li>Portland par 127,Orlando, FL</li>
                <li>(123) 456-7891</li>
                <li>alice.barkley@example.com</li>
              </ul>
            </div> --> */}

                <h3 className="resume-title">Education</h3>
                <div className="resume-item">
                  <h4>B.Tech, Computer Engineering</h4>
                  <h5>2018 - 2022</h5>
                  <p>
                    <b>KIIT University, Bhubaneswar, IND</b>
                  </p>
                  <p>
                    <ul>
                      <li>Secured 9.36 CGPA with minor in FinTech</li>
                      <li>Member of Advisory Body, KIIT E-Cell</li>
                      <li>Course Coordinator & Web Developer, KIIT E-labs</li>
                      <li>Senior Coordinator & Cloud Architect, IoT Lab</li>
                    </ul>
                  </p>
                </div>
                <div className="resume-item">
                  <h4>Matric & Intermediate, CBSE</h4>
                  <h5>2007 - 2017</h5>
                  <p>
                    <b>Delhi Public School, Kolkata, IND</b>
                  </p>
                  <p>
                    <ul>
                      <li>Completed 10+2 with 90% grade in 2017</li>
                      <li>Completed Matriculation with 10 CGPA in 2015</li>
                    </ul>
                  </p>
                </div>
                <br />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="container py-4 px-4 containerBox">
                <h3 className="resume-title">Certified Trainings</h3>

                <div className="resume-item">
                  <h4>
                    <a href="https://www.credly.com/badges/625a3360-ba76-4c20-90e3-a3328bac58e3/public_url">
                      Red Hat® Certified Specialist in Containers and Kubernetes
                    </a>
                  </h4>
                  <h5>February 2022</h5>
                </div>

                <br />

                <div className="resume-item">
                  <h4>
                    <a href="https://drive.google.com/file/d/1aZFLkIJMlk3afHDJXh73S2lLKTcD03VF/view?usp=sharing">
                      PCAP : Python Certification
                    </a>
                  </h4>
                  <h5>August 2021</h5>
                </div>

                <br />

                <div className="resume-item">
                  <h4>
                    <a href="https://www.credly.com/badges/2a07631f-0d82-42a5-8530-b9b429fe2926/public_url">
                      CCNA: Introduction to Networks
                    </a>
                  </h4>
                  <h5>July 2021</h5>
                </div>

                <br />

                <div className="resume-item">
                  <h4>
                    <a href="https://www.credly.com/badges/f2269f3a-c67e-47fb-8b8f-ed1f084a5ae0/public_url">
                      Microsoft Certified: Azure Fundamentals
                    </a>
                  </h4>
                  <h5>June 2021</h5>
                </div>

                <br />

                <div className="resume-item">
                  <h4>
                    <a href="https://drive.google.com/file/d/1_YafsmSGe0UuGXaFusjVWdOQjPYHk6Bv/view?usp=sharing">
                      RedHat Ansible Training Certificate
                    </a>
                  </h4>
                  <h5>October 2020</h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      {/* <!-- End Resume Section --> */}
      <br />
    </div>
  );
};
