import React from "react";
import { Formik } from "formik";
import classnames from 'classnames';
import { string, object } from "yup";
import axios from "axios";
import { Row, Col, Button, Form } from "react-bootstrap";
import useCheckMobileScreen from "../hooks/useCheckMobileScreen";

interface IContactProps {
  name: string;
  email: string;
  subject: string;
  message: string;
}

const initialValues: IContactProps = {
  name: "",
  email: "",
  subject: "",
  message: "",
};

const validate: any = object({
  name: string().required("Name is required"),
  email: string().email("Invalid email").required("Email is required"),
  subject: string().required("Subject is required"),
  message: string()
    .required("Message is required")
    .min(20, "Message must be at least 20 characters"),
});

const formSubmit = (values: IContactProps, { setSubmitting, resetForm }) => {
  setSubmitting(true);
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios
    .post("https://formsubmit.co/ajax/2b90c706f0ae682a5f6a19a9caf16cc1", values)
    .then((res) => {
      alert(JSON.stringify("Your message has been sent successfully"));
      console.log(res);
      setSubmitting(false);
      resetForm();
    })
    .catch((err) => {
      alert(JSON.stringify("Something went wrong. Please Try Again."));
      console.log(err);
      setSubmitting(false);
    });
};

export const Contact = () => {

  const isMobile:boolean = useCheckMobileScreen();

  return (
    <div>
      {/* <!-- ======= Contact Section ======= --> */}
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Contact Me.</h2>
          </div>

          <Row mt={1}>
            <Col lg={4} className={classnames('mb-3', { 'pt-5': !isMobile })}>
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>Kolkata, India</p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>avikkundu8d@gmail.com</p>
                </div>

                {/* <!-- <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>+91 5589 55488 55s</p>
              </div> --> */}
              </div>
            </Col>

            <Col lg={8} mt={5} className="mt-lg-0">
              <div className="container py-4 px-4 containerBox">
                <Row>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validate}
                    onSubmit={formSubmit}
                  >
                    {({
                      values,
                      errors,
                      handleSubmit,
                      handleChange,
                      isSubmitting,
                      isValidating,
                      touched,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row>
                          <Col md={6} className="mb-3">
                            <Form.Group controlId="validationFormik01">
                              <Form.Control
                                type="text"
                                name="name"
                                placeholder="Your Name"
                                value={values.name}
                                onChange={handleChange}
                                isValid={touched.name && !errors.name}
                                isInvalid={!!errors.name}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.name}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} mt={3} className="mt-md-0">
                            <Form.Group controlId="validationFormik02">
                              <Form.Control
                                type="email"
                                name="email"
                                placeholder="Your Email Address"
                                value={values.email}
                                onChange={handleChange}
                                isValid={touched.email && !errors.email}
                                isInvalid={!!errors.email}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="py-3">
                          <Col mt={3}>
                            <Form.Group controlId="validationFormik03">
                              <Form.Control
                                type="text"
                                name="subject"
                                placeholder="Subject"
                                value={values.subject}
                                onChange={handleChange}
                                isValid={touched.subject && !errors.subject}
                                isInvalid={!!errors.subject}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.subject}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col mt={3} className="form-group">
                            <Form.Group controlId="validationFormik04">
                              <Form.Control
                                type="text"
                                name="message"
                                as="textarea"
                                rows={5}
                                placeholder="Message"
                                value={values.message}
                                onChange={handleChange}
                                isValid={touched.message && !errors.message}
                                isInvalid={!!errors.message}
                              />
                            </Form.Group>
                          </Col>
                          <div className="text-center py-3">
                            <Button
                              className="btn btn-primary"
                              style={{ padding: "15px 40px 15px" }}
                              type="submit"
                              disabled={isSubmitting || isValidating}
                            >
                              Send Message
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* <!-- End Contact Section --> */}
    </div>
  );
};
