import React from 'react'

export const Portfolio = () => {
  return (
    <div>
            {/* <!-- ======= Portfolio Section ======= --> */}
    <section id="portfolio" className="portfolio section-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>My Projects</h2>
          <p>The list of my projects sorted by where I've worked on them. Details of each project can
            be found on my <a href="https://github.com/Lucifergene/">Github</a></p>
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
            <ul id="portfolio-flters">
              <li data-filter="*" className="filter-active">All</li>
              <li data-filter=".filter-webd">Web</li>
              <li data-filter=".filter-ai">AI</li>
              <li data-filter=".filter-cloud">Cloud</li>
            </ul>
          </div>
        </div>

        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

          <div className="col-lg-6 col-md-6 portfolio-item filter-webd">
            <div className="portfolio-wrap">
              <img src="assets/img/works/blogvista.png" className="img-fluid" alt="" />
              <div className="portfolio-info">
                <h4>BlogVista</h4>
                <p>A Blogging Platform</p>
                <div className="portfolio-links">
                  <a href="https://github.com/Lucifergene/blogvista" target="_blank"><i className='bx bxl-github'></i></a>

                  <a href="https://blogvista.herokuapp.com/" target="_blank" title="Live Website"><i
                      className="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 portfolio-item filter-webd filter-ai filter-cloud">
            <div className="portfolio-wrap">
              <img src="assets/img/works/covid-web.png" className="img-fluid" alt="" />
              <div className="portfolio-info">
                <h4>COVID-Predictioned</h4>
                <p>Perform Predictions based on Chest X-Ray Images Online</p>
                <div className="portfolio-links">
                  <a href="https://github.com/Lucifergene/covid-prediction-classNameifier" target="_blank"><i
                      className='bx bxl-github'></i></a>

                  <a href="https://covid-predictioned.herokuapp.com/" target="_blank" title="Live Website"><i
                      className="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 portfolio-item filter-webd">
            <div className="portfolio-wrap">
              <img src="assets/img/works/kiit-mun.png" className="img-fluid" alt="" />
              <div className="portfolio-info">
                <h4>KIIT International MUN 2021</h4>
                <p>Official Website for KIIT MUN 2020</p>
                <div className="portfolio-links">
                  <a href="https://kiitmun.org/" target="_blank" title="Live Website"><i className="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 portfolio-item filter-webd">
            <div className="portfolio-wrap">
              <img src="assets/img/works/moz-web.png" className="img-fluid" alt="" />
              <div className="portfolio-info">
                <h4>Mozilla Club</h4>
                <p>Official Site for the BBSR Community</p>
                <div className="portfolio-links">
                  <a href="https://github.com/mozilla-bbsr/mozillabbsr-web" target="_blank"><i
                      className='bx bxl-github'></i></a>

                  <a href="https://mozilla-frontweb.web.app/" target="_blank" title="Live Website"><i
                      className="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 portfolio-item filter-web">
            <div className="portfolio-wrap">
              <img  src="assets/img/works/elabs.png" className="img-fluid" alt="" />
              <div className="portfolio-info">
                <h4>KIIT E-Labs Official Site</h4>
                <p>E-Labs is a student-run peer-to-peer technical engagement platform.</p>
                <div className="portfolio-links">
                  <a href="https://github.com/Kiit-Elabs/Elabs_web_frontend" target="_blank"><i
                      className='bx bxl-github'></i></a>

                  <a href="https://elabskiit.me/" target="_blank" title="Live Website"><i className="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 portfolio-item filter-ai">
            <div className="portfolio-wrap">
              <img  src="assets/img/works/mask.png" className="img-fluid" alt="" />
              <div className="portfolio-info">
                <h4>Performing Instance Segmentation on X-Ray Images with Mask R-CNN</h4>
                <p>Segmenting GGOs in COVID-19 Chest X-Rays</p>
                <div className="portfolio-links">
                  <a href="https://medium.com/@avik6028/performing-instance-segmentation-on-x-ray-images-with-mask-r-cnn-761dbca23511"
                    target="_blank"><i className='bx bxl-medium'></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 portfolio-item filter-ai">
            <div className="portfolio-wrap">
              <img  src="assets/img/works/mlops.png" className="img-fluid" alt="" />
              <div className="portfolio-info">
                <h4>Building the Perfect Face Recognition Model with the Integration of ML-Ops</h4>
                <p>Automating the process of Hyperparameter Tuning with DevOps</p>
                <div className="portfolio-links">
                  <a href="https://medium.com/@avik6028/building-the-perfect-face-recognition-model-with-the-integration-of-ml-ops-a28c43994c23"
                    target="_blank"><i className='bx bxl-medium'></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 portfolio-item filter-cloud">
            <div className="portfolio-wrap">
              <img  src="assets/img/works/docker-mern.png" className="img-fluid" alt="" />
              <div className="portfolio-info">
                <h4>Dockerizing a MERN Stack Web Application</h4>
                <p>Using Docker Containers to build Web Application</p>
                <div className="portfolio-links">
                  <a href="https://medium.com/mozilla-club-bbsr/dockerizing-a-mern-stack-web-application-ebf78babf136"
                    target="_blank"><i className='bx bxl-medium'></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 portfolio-item filter-cloud">
            <div className="portfolio-wrap">
              <img  src="assets/img/works/jenkins-devops.png" className="img-fluid" alt="" />
              <div className="portfolio-info">
                <h4>DevOps Automation using Jenkins</h4>
                <p>Integrating Docker and Jenkins with Git Branches</p>
                <div className="portfolio-links">
                  <a href="https://medium.com/@avik6028/devops-automation-using-jenkins-cb27032d8f9f" target="_blank"><i
                      className='bx bxl-medium'></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 portfolio-item filter-cloud">
            <div className="portfolio-wrap">
              <img  src="assets/img/works/url-shortener.png" className="img-fluid" alt="" />
              <div className="portfolio-info">
                <h4>Building Serverless URL Shortener Service on AWS</h4>
                <p>Serverless implementation of an URL Shortener Services</p>
                <div className="portfolio-links">
                  <a href="https://avikkundu.medium.com/building-serverless-url-shortener-service-on-aws-e1662d015d70"
                    target="_blank"><i className='bx bxl-medium'></i></a>

                  <a href="https://short-linker.lucifergene.workers.dev/" target="_blank" title="Live Website"><i
                      className="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    {/* <!-- End Portfolio Section --> */}
    </div>
  )
}
