import React from "react";
import classnames from "classnames";
import { Button, Card, Container, Col, Row } from "react-bootstrap";
import useCheckMobileScreen from "../hooks/useCheckMobileScreen";

export const Publication = () => {
  const isMobile: boolean = useCheckMobileScreen();
  return (
    <div>
      {/* <!-- ======= Publications Section ======= --> */}
      <section id="publications" className="testimonials section-bg">
        <Container data-aos="fade-up">
          <div className="section-title">
            <h2>Publications</h2>
          </div>

          <div className="container-fluid content-row">
            <Row>
              <Col lg={4} md={4} mb={4}>
                <Card
                  className={classnames("text-center","h-10", { "mb-3": isMobile })}
                >
                  <Card.Header>Springer Publication</Card.Header>
                  <Card.Body>
                    <Card.Title>
                      Diagnosis of Covid-19 Cases with X-ray Images Using MLOps
                    </Card.Title>
                    <Card.Text style={{ fontStyle: "normal" }}>
                      In this paper, the authors have proposed a method through
                      which the process of Hyper Parameter Tuning of the Deep
                      Learning Models can be automated using the concept of
                      MLOps.
                    </Card.Text>
                    <br />
                    <Button
                      variant="primary"
                      href="#"
                      target="_blank"
                      title="Will be Published Soon"
                    >
                      Published Paper
                    </Button>
                    <Button
                      variant="success"
                      href="https://drive.google.com/file/d/1IanqcHnLmxP9DQDHQZwFcM_AEHyLWx83/view?usp=sharing"
                      target="_blank"
                      style={{ marginLeft: "7px" }}
                    >
                      Certificate
                    </Button>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={4} md={4} mb={4}>
                <Card className={classnames("text-center","h-10", { "mb-3": isMobile })}>
                  <Card.Header>IEEE Xplore</Card.Header>
                  <Card.Body>
                    <Card.Title>
                      Diagnosis of Radiological Images using Mask R-CNN
                    </Card.Title>
                    <Card.Text style={{ fontStyle: "normal" }}>
                      In this study, the authors have described a Mask R-CNN
                      (region-based convolution neural network) approach for the
                      detection of the ground glass opacities (GGOs) in chest CT
                      images of COVID-19 infected persons.
                    </Card.Text>
                    <Button
                      variant="primary"
                      href="https://ieeexplore.ieee.org/document/9445190"
                      target="_blank"
                    >
                      Published Paper
                    </Button>
                    <Button
                      variant="success"
                      href="https://drive.google.com/file/d/1TztjQUmjbwxf1noxwcIts1ENTp3s0zoB/view?usp=sharing"
                      target="_blank"
                      style={{ marginLeft: "7px" }}
                    >
                      Certificate
                    </Button>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={4} md={4} mb={4}>
                <Card className="h-10 text-center">
                  <Card.Header>IEEE Xplore</Card.Header>
                  <Card.Body>
                    <Card.Title>
                      Framework for Selection of the IoT Device on Execution
                      Context
                    </Card.Title>
                    <Card.Text style={{ fontStyle: "normal" }}>
                      In this study, the authors propose a novel framework to
                      select the most suitable device for performing the task,
                      based on execution context.
                    </Card.Text>
                    <br /> <br />
                    <Button
                      variant="primary"
                      href="https://ieeexplore.ieee.org/abstract/document/9753535/"
                      target="_blank"
                    >
                      Published Paper
                    </Button>
                    <Button
                      variant="success"
                      href="https://drive.google.com/file/d/1F4oaJpOqApBPamFvT9-RyTe9YONQ2R4A/view?usp=sharing"
                      target="_blank"
                      style={{ marginLeft: "7px" }}
                    >
                      Certificate
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      {/* <!-- End Publications Section --> */}
    </div>
  );
};
