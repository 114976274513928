import React from "react";
import classnames from "classnames";
import useCheckMobileScreen from "../hooks/useCheckMobileScreen";

const sortLevel = (level) => {
  const sorted = Object.keys(level)
    .sort((key1, key2) => level[key2] - level[key1])
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: level[key],
      }),
      {}
    );

  return sorted;
};

export const Skills = ({ level }) => {
  const isMobile: boolean = useCheckMobileScreen();
  const { languages, frameworks, devops } = level;
  return (
    <div>
      <section id="skills" className="skills section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>My Skills</h2>
            {/* <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint
          consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit
          in iste officiis commodi quidem hic quas.</p> --> */}
          </div>
          <div className="row">
            <div className="col-md-4">
            <div className={classnames("container py-4 px-4 containerBox", {"mb-3" : isMobile})}>
                <div className="text-center">
                  <strong>Programming Languages</strong>
                </div>
                {Object.keys(sortLevel(languages)).map((key, i) => (
                  <div className="progress" key={i}>
                    <span className="skill">
                      {key}
                      <i className="val">{languages[key]}</i>
                    </span>
                    <div className="progress-bar-wrap">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow={languages[key]}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{
                          width: `${languages[key]}%`,
                          borderRadius: "10px",
                        }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-4">
            <div className={classnames("container py-4 px-4 containerBox", {"mb-3" : isMobile})}>
                <div className="text-center">
                  <strong>Frameworks | Databases</strong>
                </div>
                {Object.keys(sortLevel(frameworks)).map((key, i) => (
                  <div className="progress" key={i}>
                    <span className="skill">
                      {key}
                      <i className="val">{frameworks[key]}</i>
                    </span>
                    <div className="progress-bar-wrap">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow={frameworks[key]}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{
                          width: `${frameworks[key]}%`,
                          borderRadius: "10px",
                        }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-4">
              <div className="container py-4 px-4 containerBox">
                <div className="text-center">
                  <strong>DevOps | Cloud </strong>
                </div>
                {Object.keys(sortLevel(devops)).map((key, i) => (
                  <div className="progress" key={i}>
                    <span className="skill">
                      {key}
                      <i className="val">{devops[key]}</i>
                    </span>
                    <div className="progress-bar-wrap">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow={devops[key]}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{
                          width: `${devops[key]}%`,
                          borderRadius: "10px",
                        }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* <div className="container py-4 px-4 containerBox" >
          <div className="row skills-content">
            <div className="col-lg-6">

              <div className="progress">
                <span className="skill">
                  Frontend Development<i className="val">70%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={70}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: "70%" , borderRadius: "10px"}}
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  Backend Development <i className="val">85%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={85}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: "85%" }}
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  DevOps <i className="val">90%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={90}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: "90%" }}
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  Cloud Computing<i className="val">85%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={85}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: "85%" }}
                  ></div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="progress">
                <span className="skill">
                  JavaScript <i className="val">70%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={70}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: "70%" }}
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  Python<i className="val">90%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={90}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: "90%" }}
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  Docker<i className="val">90%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={90}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: "90%" }}
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  AWS<i className="val">80%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={80}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: "80%" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          </div> */}
        </div>
      </section>
      {/* <!-- End Skills Section --> */}
    </div>
  );
};
