import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import "./App.css";
import { About } from "./components/About";
import { Achievements } from "./components/Achievements";
import { Header } from "./components/Header";
import { Hero } from "./components/Hero";
import { Portfolio } from "./components/Portfolio";
import { Resume } from "./components/Resume";
import { Services } from "./components/Services";
import { Skills } from "./components/Skills";
import { Publication } from "./components/Publication";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import {
  enable as enableDarkMode,
  disable as disableDarkMode,
} from "darkreader";

function App() {
  const [darkMode, setDarkMode] = React.useState<Boolean>(false);
  const [darkReaderEnabled, setDarkReaderEnabled] =
    React.useState<Boolean>(false);

  const darkModeSettings = {
    brightness: 100,
    contrast: 90,
    sepia: 10,
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
    AOS.refresh();
  }, []);

  useEffect(() => {
    if (darkMode && !darkReaderEnabled) {
      enableDarkMode(darkModeSettings);
      setDarkReaderEnabled(true);
    } else {
      disableDarkMode();
      setDarkReaderEnabled(false);
    }
  }, [darkMode]);

  const skillLevel = {
    languages: {
      TypeScript: 80,
      Go: 50,
      Python: 90,
      "C++": 90,
      Bash: 50,
    },
    frameworks: {
      react: 80,
      nodejs: 80,
      flask: 80,
      mysql: 70,
      mongodb: 70,
    },
    devops: {
      aws: 70,
      azure: 80,
      docker: 90,
      kubernetes: 60,
      Jenkins: 60,
    },
  };

  return (
    <div className="App">
      <Header darkReaderEnabled={darkReaderEnabled} setDarkMode={setDarkMode} />
      <Hero />
      <main id="main">
        <About />
        <Skills level={skillLevel} />
        <Resume />
        <Portfolio />
        <Services />
        <Achievements />
        <Publication />
        <Contact />
      </main>
      <Footer />
    </div>
  );
}

export default App;
