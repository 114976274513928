import axios from "axios";
import React, { useEffect, useState } from "react";

export const Services = () => {
  const [blogs, setBlogs] = useState<any>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    axios
      .get(
        "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Favikkundu.medium.com%2Ffeed"
      )
      .then((res) => res.data)
      .then((data) => {
        if (data.status === "ok") {
          setBlogs([...data.items]);
          if (data.items.length > 0) {
            setIsLoaded(true);
          }
        }
      });
  }, []);
  console.log(blogs);

  return (
    <div>
      {/* <!-- ======= Services Section ======= --> */}
      <section id="services" className="services">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Latest Blog Posts</h2>
            <p>
              The list of my Articles sorted by where I've worked on them. You
              can view my Medium Profile from
              <a href="https://avikkundu.medium.com"> here</a>
            </p>
          </div>

          <div className="row" id="jsonContent">
            {/* <!-- Dynamically Filling of Latest Posts via RSS Feeds --> */}
            {isLoaded ? (
              blogs.slice(0, 6).map((blog: any) => (
                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                  key={blog.link}
                >
                  <div className="icon-box iconbox-blue">
                    <h4>
                      <a href={blog.link} target="_blank">
                        {blog.title}
                      </a>
                    </h4>
                    <a
                      href={blog.link}
                      className="btn btn-outline-success"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center">
                <h4>Something is not right!</h4>
                <p>
                  Please <a href="https://avikkundu.medium.com">click here</a>{" "}
                  to get to my Medium Profile page.
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
      {/* <!-- End Services Section --> */}
    </div>
  );
};
